<template>
    <div class="contents">
        <div class="contents-title"></div>
        <div class="contents-box">
            <div class="contents-menu">
                <div class="contents-menu-text">{{ meta_cites }}</div>
                <div class="contents-menu-tabs">
                    <div class="menu-tab contents-menu-text" :class="{'menu-tab-active': land_type == 'hongkong'}" @click="onClickTab('hongkong')">{{ hongkong }}</div>
                    <div class="menu-tab contents-menu-text" :class="{'menu-tab-active': land_type == 'amsterdam'}" @click="onClickTab('amsterdam')">{{ amsterdam }}</div>
                </div>                
            </div>
            <div class="contents-map">
                <div class="sale-info-hongkong" v-if="land_type == 'hongkong'"></div>
                <div class="sale-info-amsterdam" v-else-if="land_type == 'amsterdam'"></div>
                <div class="canvas-box">
                    <MapLand ref="refMapLand" :mapId="land_type" :is4th="true"/>
                </div>             
            </div>
            <div class="contents-legend">
                <div class="legend-menu">
                    <div class="play"></div>
                    <span>{{ play2earn }}</span>
                </div>
                <div class="legend-menu">
                    <div class="management"></div>
                    <span>{{ management_owned }}</span>
                </div>
                <div class="legend-menu">
                    <div class="sale"></div>
                    <span>{{ land_sale }}</span>
                </div>
            </div>
            <div class="contents-buy">
                <div class="btn-buy">
                    <!-- <span class="buy-now-tooltip">{{buy_now_tooltip}}</span> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppConfig from '@/App.Config.js'
import MapLand from '@/components/MapLand.vue'
import WalletAPI from '@/features/WalletAPI.js'
import {
	FORTMATIC,
	BITSKI,
	formatChainId,
	WALLETCONNECT,
	renderNetworkName
} from '@/features/Common.js'
import {
	coinbaseProvider,
	fortmaticProvider,
	walletConnectProvider,
	bitski,
} from '../features/Connectors.js'
import Utils from '../features/Utils.js';
import Web3 from 'web3';

var wAPI = new WalletAPI();

var gConfig = AppConfig()

export default {
    components: {
        MapLand
    },
    mounted() {
        this.checkLandCount();
    },
	name: 'Market-4th-LandSale',
    data() {
        return {
            meta_cites: this.$t('market.4th-landsale.meta-cites'),
            hongkong: this.$t('market.4th-landsale.hongkong'),
            amsterdam: this.$t('market.4th-landsale.amsterdam'),
            play2earn: this.$t('market.4th-landsale.play2earn'),
            management_owned: this.$t('market.4th-landsale.management-owned'),
            land_sale: this.$t('market.4th-landsale.land-sale'),
            land_type: 'hongkong',
            buy_now_tooltip: this.$t('market.4th-landsale.buy-now-tooltip'),
            soldout: false
        }
    },    
    methods: {
        onClickTab(value) {
            this.land_type = value;            
        },
        async onClickBuy(value) {     
            if(this.soldout) {
                return;
            }
            
            let provider = null
			const loginBy = window.localStorage.getItem('loginBy')
			switch (loginBy) {
				case FORTMATIC:
					provider = fortmaticProvider.getProvider()
					break
				case WALLETCONNECT:
					provider = walletConnectProvider
					break
				case BITSKI:
					provider = bitski.getProvider()
					break
			}

            // buy land process
            const getLandToken = {
                provider,
                method: "getLandToken",
                address: this.$store.state.userInfo.wallet_addr
            };

            const lastReceiveToken = {
                provider,
                method: "lastReceiveToken",
                address: this.$store.state.userInfo.wallet_addr
            }

            const getTotalLandTokensCount = {
                provider,
                method: "getTotalLandTokensCount",
                address: this.$store.state.userInfo.wallet_addr
            }
            let totalLandTokensCount = await wAPI.Contract4thLand(getTotalLandTokensCount);
            console.log("totalLandTokensCount : " + parseInt(totalLandTokensCount.data._hex, 16));
            if(parseInt(totalLandTokensCount.data._hex, 16) == 0) {
                // Todo.. 남은 랜드가 없음
                return;
            }

            this.mxShowLoading();
            let getPrevLandToken = await wAPI.Contract4thLand(lastReceiveToken);
            let prevGetTokenId = getPrevLandToken.data.toString(10);
            console.log("prevGetTokenId", prevGetTokenId);
          
            const res = await wAPI.Contract4thLand(getLandToken);
            
            if(res.res_code == 200) {
                // 뽑기로 얻은 tokenId : newGetTokenId;
                let newGetTokenId;
                while(true) {
                    const getLandToken = await wAPI.Contract4thLand(lastReceiveToken);                    
                    if(getLandToken.res_code == 200) {
                        newGetTokenId = getLandToken.data.toString(10);
                    }                    
                    if(prevGetTokenId != newGetTokenId){
                        break;
                    }
                    await this.sleep(1000);
                }

                this.mxCloseLoading();
                console.log("newGetTokenId", newGetTokenId);

                // land type 받아오기
                await Utils().call4thLandByTokenId(newGetTokenId, (res) => {
                    console.log(res);
                    this.mxShow4thRewardModal({
                        isShow: true,
                        type: res.premium,
                        image: res.image
                    });
                })

                // after show popup
                
            } else {
                this.mxCloseLoading();
                const errMsg = res.msg;
                this.mxShowTransactionErrorModal({
                    isShow: true
                });
            }
            

        },
        async sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            })
        },
        async checkLandCount() {
			let provider = null
			const loginBy = window.localStorage.getItem('loginBy')
			switch (loginBy) {
				case FORTMATIC:
					provider = fortmaticProvider.getProvider()
					break
				case WALLETCONNECT:
					provider = walletConnectProvider
					break
				case BITSKI:
					provider = bitski.getProvider()
					break
			}

			const getTotalLandTokensCount = {
				provider,
				method: "getTotalLandTokensCount",
				address: this.$store.state.userInfo.wallet_addr
			}
			let totalLandTokensCount = await wAPI.Contract4thLand(getTotalLandTokensCount);
			console.log("totalLandTokensCount : " + parseInt(totalLandTokensCount.data._hex, 16));
			if(parseInt(totalLandTokensCount.data._hex, 16) == 0) {
				this.soldout = true;
			}
		}
    }
}
</script>

<style lang="scss" scoped>
.contents {
	width: gREm(1260);
	@include FLEXV(space-between, center);

    .contents-title {
        width: 100%;
        height: gREm(232);
        margin-bottom: gREm(24);
        @include SetBgImage(
            url('../assets/img/4thLandSale/warnning.png')
        );        
    }

    .contents-box {
        width: 100%;

        .contents-menu-text {
            font-family: Montserrat, sans-serif;
            font-size: 1.25rem;
            font-weight: 700;
            color: #fff;
            opacity: 0.4;
        }

        .contents-menu {
            width: 100%;
            @include FLEX(space-between, center);

            .contents-menu-tabs {
                display: flex;

                .menu-tab {                    
                    margin-right: gREm(16);
                    cursor: pointer;
                }

                .menu-tab-active {
                    color: #ED7D31;
                    text-decoration: underline;
                    opacity: 1;
                }
            }
        }

        .contents-map {
            width: 100%;
            height: gREm(450);
            margin-top: gREm(16);
            @include FLEX(space-between, center);

            .sale-info-hongkong {
                width: 33%;
                height: 100%;
                margin-bottom: gREm(10);
                @include SetBgImage(
					url('../assets/img/4thLandSale/hongkong.png')
				);
            }

            .sale-info-amsterdam {
                width: 33%;
                height: 100%;
                margin-bottom: gREm(10);
                @include SetBgImage(
					url('../assets/img/4thLandSale/amsterdam.png')
				);
            }

            .canvas-box {
                flex: 1 1 auto;
                height: 100%;
                margin-bottom: gREm(10);
            }
        }

        .contents-legend {
            display: flex;
            margin-top: gREm(25);
            @include FLEX(space-around, center);

            .legend-menu {
                display: flex;

                .play {
                    background-color: rgba(255,0,0,0.5);
                    width: gREm(23);
                    height: gREm(23);
                    margin-right: gREm(20);
                }

                .management {
                    background-color: rgba(60,213,136,0.3);
                    width: gREm(23);
                    height: gREm(23);
                    margin-right: gREm(20);
                }

                .sale {
                    background-color: rgba(255,217,113,1.0);
                    width: gREm(23);
                    height: gREm(23);
                    margin-right: gREm(20);
                }

                span {
                    font-family: Montserrat, sans-serif;
                    font-size: 1rem;
                    font-weight: 700;
                    line-height: 1.5rem;
                    font-stretch: normal;
                    font-style: normal;
                    color: #FEFEFE;
                    text-decoration: none;
                }
            }            
        }

        .contents-buy {
            display: flex;
            margin: gREm(25) gREm(0);
            @include FLEX(center, center);

            .btn-buy {
                width: gREm(236);
                height: gREm(47);
                margin-top: gREm(30);
                margin-bottom: gREm(60);
                font-family: Montserrat, sans-serif;
                font-size: gREm(16);
                font-style: normal;
                font-weight: 400;
                line-height: gREm(30);
                letter-spacing: 0em;
                text-align: center;                
                border-radius: gREm(10);
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.5;
                @include SetBgImage(
					url('../assets/img/4thLandSale/soldout_btn.png')
				);
                // cursor: pointer;
                // &:hover {
                //     @include SetBgImage(
                //         url('../assets/img/4thLandSale/buy_btn_hover.png')
                //     );
				// }
                .buy-now-tooltip {
                    visibility: hidden;
                    width: 225px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px 0;
                    
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 1;
                    bottom: 120%;
                    left: 27.5%;
                    margin-left: -60px;
                }
                &:hover .buy-now-tooltip {
                    visibility: visible;
                }
            }
        }
    }    
}

@include media-max($media_small) { // 768
    .contents {
        width: 100%;
        .contents-title {
            width: 100%;
            height: calc(16.5vw);
        }

        .contents-map {
            height: 200vw !important;
            display: block !important;

            .sale-info-hongkong {
                height: 100vw !important;
                width: 100% !important;
            }

            .sale-info-amsterdam {
                height: 100vw !important;
                width: 100% !important;      
            }

            .canvas-box {
                height: 100vw !important;
            }
        }
    }
}
</style>